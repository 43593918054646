import React from 'react';
import { Locales, LegalTextSubjects } from '../common/apiTypes.js';
import { PurchasingContactInfoPage } from './pages/LocalizationPages/PurchasingContactInfoPage/index.js';
import { HomePage } from './pages/HomePage/index.js';
import { IdeaInspirationPage } from './pages/IdeaInspirationPage/index.js';
import { ArticlePage } from './pages/ArticlePage/index.js';
import { RecipePage } from './pages/RecipePage/index.js';
import { ContactListPage } from './pages/ContactListPage/index.js';
import { WholesaleContactInfoPage } from './pages/ContactInfoPages/WholesaleContactInfoPage.js';
import { LocationContactInfoPage } from './pages/ContactInfoPages/LocationContactInfoPage.js';
import { LegalTextPage } from './pages/LegalTextPage/index.js';
import { LegalPage } from './pages/LegalPage/index.js';
import { MagazinesPage } from './pages/MagazinesPage/index.js';
import { ProductsPage } from './pages/ProductsPage/index.js';
import { BrandCollectionPage } from './pages/BrandCollectionPage/index.js';
import { NewProductPage } from './pages/NewProductPage/index.js';
import { ProductBrandsCollectionPage } from './pages/ProductBrandCollection/index.js';
import { ProductBrandPage } from './pages/ProductBrand/index.js';
import { SearchPage } from './pages/SearchPage/index.js';
import { MaintenanceInfoPage } from './pages/MaintenanceInfoPage/index.js';
import { NotFoundPageWrapper } from './pages/NotFoundPageWrapper/index.js';
import { LandingPageWrapper } from './components/LandingPagesWrapper.js';
import { languageBasePathRedirectTargetUrls } from './staticData.js';
import { contactForMediaPageUrls, contactForSuppliersPageUrls, contactListPageUrls, customerServiceListPageUrls, locationListPageUrls, salesContactListPageUrls, wholesaleListPageUrls, } from '../common/urlPaths.js';
import { PartnerContentPage } from './pages/PartnerContentPage/index.js';
import { AuthenticatedLandingPage } from './components/AuthenticatedLandingPage.js';
import { ForceLogOut } from './components/ForceLogOut.js';
export const RouteType = {
    redirect: 0,
    component: 1,
    wrapper: 2,
};
const notFoundRoute = {
    path: '*',
    key: '404route',
    exact: false,
    component: NotFoundPageWrapper,
    type: RouteType.component,
};
function getChildRoutes(routeDefs) {
    return routeDefs.concat(notFoundRoute);
}
const getGenericLandingpageRoute = (language) => {
    const pathPrefix = language === 'fi' ? '' : `${language}/`;
    return {
        path: `/${pathPrefix}:landingPage+`,
        key: `/${pathPrefix}:landingPage`,
        exact: true,
        component: (props) => React.createElement(LandingPageWrapper, { routeProps: props, language: language }),
        type: RouteType.component,
    };
};
const getSwedishRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: false,
        type: RouteType.wrapper,
        childRoutes: getChildRoutes([
            ...getContactInfoRoutes({ path: 'kontakt-information', language: 'sv', locale: Locales.SWEDISH }),
            getGenericLandingpageRoute('sv'),
            {
                path,
                key: `redirect-${path}`,
                exact: true,
                to: languageBasePathRedirectTargetUrls.sv,
                type: RouteType.redirect,
            },
        ]),
    },
];
const getRussianRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: false,
        type: RouteType.wrapper,
        childRoutes: getChildRoutes([
            getGenericLandingpageRoute('ru'),
            {
                path,
                key: `redirect-${path}`,
                exact: true,
                to: languageBasePathRedirectTargetUrls.ru,
                type: RouteType.redirect,
            },
        ]),
    },
];
const getEnglishRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: false,
        type: RouteType.wrapper,
        childRoutes: getChildRoutes([
            ...getContactInfoRoutes({ path, language: 'en', locale: Locales.ENGLISH }),
            getGenericLandingpageRoute('en'),
            {
                path,
                key: `redirect-${path}`,
                exact: true,
                to: languageBasePathRedirectTargetUrls.en,
                type: RouteType.redirect,
            },
        ]),
    },
];
const getIdeasAndInspirationRoutes = ({ path }) => [
    {
        path: [path, `${path}/:filterType`],
        key: path,
        exact: true,
        component: IdeaInspirationPage,
        type: RouteType.component,
    },
    {
        path: `${path}/artikkelit/:articleSlug`,
        key: `${path}/artikkelit`,
        exact: true,
        component: ArticlePage,
        type: RouteType.component,
    },
    {
        path: `${path}/reseptit/:recipeSlug`,
        key: `${path}/reseptit`,
        exact: true,
        component: RecipePage,
        type: RouteType.component,
    },
];
const contactInfoMetaDescriptions = {
    supplier: 'Kespron tavoitteena on tehostaa ja kehittää asiakastarpeiden pohjalta tavarantoimittajan ja tukkukaupan yhteistyötä yhteisten asiakkaiden eduksi.',
};
const getContactInfoRoutes = ({ path, language = 'fi', locale = Locales.FINNISH }) => [
    {
        path: Object.values(contactForSuppliersPageUrls),
        key: `${path}/tavarantoimittajille`,
        exact: true,
        component: ({ location }) => React.createElement(PurchasingContactInfoPage, { canonicalPath: contactForSuppliersPageUrls[language], slug: "suppliers", description: contactInfoMetaDescriptions.supplier, location: location, locale: locale }),
        type: RouteType.component,
    },
    {
        path: Object.values(contactForMediaPageUrls),
        key: `${path}/medialle`,
        exact: true,
        component: ({ location }) => React.createElement(PurchasingContactInfoPage, { canonicalPath: contactForMediaPageUrls[language], slug: "media", description: contactInfoMetaDescriptions.supplier, location: location, locale: locale }),
        type: RouteType.component,
    },
    {
        path: Object.values(wholesaleListPageUrls).map((url) => `${url}/:contactId`),
        key: `${path}/noutotukut`,
        exact: true,
        component: WholesaleContactInfoPage,
        type: RouteType.component,
    },
    {
        path: Object.values(locationListPageUrls).map((url) => `${url}/:contactId`),
        key: `${path}/sijainnit`,
        exact: true,
        component: LocationContactInfoPage,
        type: RouteType.component,
    },
    {
        path: [...Object.values(contactListPageUrls), ...Object.values(wholesaleListPageUrls), ...Object.values(locationListPageUrls), ...Object.values(customerServiceListPageUrls), ...Object.values(salesContactListPageUrls)],
        key: path,
        exact: true,
        component: ContactListPage,
        type: RouteType.component,
    },
];
const getLegalRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: false,
        component: LegalPage,
        type: RouteType.component,
        childRoutesOpt: getChildRoutes([
            {
                path: `${path}/kayttoehdot`,
                key: `${path}/kayttoehdot`,
                exact: true,
                component: ({ location }) => React.createElement(LegalTextPage, { subject: LegalTextSubjects.TERMS_OF_USE, location: location }),
                type: RouteType.component,
            },
            {
                path: `${path}/tietosuojaseloste`,
                key: `${path}/tietosuojaseloste`,
                exact: true,
                component: ({ location }) => React.createElement(LegalTextPage, { subject: LegalTextSubjects.PRIVACY_POLICY, location: location }),
                type: RouteType.component,
            },
            {
                path,
                key: `redirect-${path}`,
                exact: true,
                type: RouteType.redirect,
                to: `${path}/kayttoehdot`,
            },
        ]),
    },
];
const getMagazinesRoutes = ({ path }) => [
    {
        path: [path, `${path}/:magazineSlug`],
        key: path,
        exact: true,
        component: (props) => React.createElement(MagazinesPage, Object.assign({}, props, { magazineSlug: props.match.params.magazineSlug, locale: Locales.FINNISH })),
        type: RouteType.component,
    },
];
const getAuthenticationRoutes = ({ path }) => [
    {
        path: [path, `${path}/callback`],
        key: path,
        exact: true,
        component: () => React.createElement(ForceLogOut, null),
        type: RouteType.component,
    },
];
const getProductsRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: true,
        component: ProductsPage,
        type: RouteType.component,
    },
    {
        path: `${path}/vain-kesprosta`,
        key: `${path}/vain-kesprosta`,
        exact: true,
        component: BrandCollectionPage,
        type: RouteType.component,
    },
    {
        path: `${path}/tuotemerkit`,
        key: `${path}/tuotemerkit`,
        exact: true,
        component: ProductBrandsCollectionPage,
        type: RouteType.component,
    },
    {
        path: `${path}/tuotemerkit/:productBrandSlug`,
        key: `${path}/tuotemerkit/productBrandSlug`,
        exact: true,
        component: ProductBrandPage,
        type: RouteType.component,
    },
    {
        path: `${path}/:productSlugPrefix:ean(\\d{8}|\\d{12}|\\d{13}|\\d{14})`,
        key: `${path}/productSlug`,
        exact: true,
        component: NewProductPage,
        type: RouteType.component,
    },
];
const getFinnishRoutes = ({ path }) => [
    {
        path,
        key: path,
        exact: false,
        type: RouteType.wrapper,
        childRoutes: getChildRoutes([
            {
                path,
                key: `2-${path}`,
                exact: true,
                component: HomePage,
                type: RouteType.component,
            },
            ...getIdeasAndInspirationRoutes({ path: '/ideat-ja-inspiraatiot' }),
            ...getContactInfoRoutes({ path: '/yhteystiedot' }),
            ...getLegalRoutes({ path: '/lakisaateinen' }),
            ...getMagazinesRoutes({ path: '/tuotekuvastot-ja-lehdet' }),
            ...getProductsRoutes({ path: '/tuotteet' }),
            ...getAuthenticationRoutes({ path: '/kirjaudu' }),
            {
                path: `/kirjautunut`,
                key: `/kirjautunut`,
                exact: true,
                unAuthenticatedRedirect: '/kirjaudu/ksso',
                component: (props) => React.createElement(AuthenticatedLandingPage, { routeProps: props, landingPage: 'kirjautunut', language: 'fi' }),
                type: RouteType.component,
            },
            {
                path: `/kirjaudu/login-callback`,
                key: path,
                exact: true,
                to: '/',
                type: RouteType.redirect,
            },
            {
                path: '/haku',
                key: 'redirect-/haku',
                exact: true,
                type: RouteType.redirect,
                to: '/haku/tuotteet',
            },
            {
                path: '/haku/artikkelit/:articleType',
                key: '/haku/resultFilter/articleType',
                exact: false,
                component: ({ match, location }) => React.createElement(SearchPage, { articleType: match.params.articleType, resultFilter: "articles", location: location }),
                type: RouteType.component,
            },
            {
                path: '/haku/:resultFilter',
                key: '/haku/resultFilter',
                exact: false,
                component: ({ match, location }) => {
                    const resultFilterMap = {
                        artikkelit: 'articles',
                        tuotteet: 'products',
                        reseptit: 'recipes',
                        yhteystiedot: 'contacts',
                        sisallot: 'contentPages',
                    };
                    return React.createElement(SearchPage, { resultFilter: resultFilterMap[match.params.resultFilter], location: location });
                },
                type: RouteType.component,
            },
            {
                path: '/kumppani/:partner',
                key: '/kumppani/partner',
                exact: true,
                component: PartnerContentPage,
                type: RouteType.component,
            },
            {
                path: '/kespronet-hairiotiedotteet',
                key: '/kespronet-hairiotiedotteet',
                exact: true,
                component: MaintenanceInfoPage,
                type: RouteType.component,
            },
            {
                path: '/kespro',
                key: 'redirect-/kespro',
                exact: true,
                type: RouteType.redirect,
                to: '/',
            },
            {
                path: '/foodsteri',
                key: 'redirect-/foodsteri',
                exact: true,
                type: RouteType.redirect,
                to: '/palvelut/foodsteri',
            },
            {
                path: '/koulutukset',
                key: 'redirect-/koulutukset',
                exact: true,
                type: RouteType.redirect,
                to: '/palvelut/koulutukset',
            },
            {
                path: '/logistiikkapalvelu',
                key: 'redirect-/logistiikkapalvelu',
                exact: true,
                type: RouteType.redirect,
                to: '/palvelut/logistiikkapalvelu',
            },
            {
                path: '/ravintolatukku',
                key: 'redirect-/ravintolatukku',
                exact: true,
                type: RouteType.redirect,
                to: '/palvelut/ravintolatukku',
            },
            getGenericLandingpageRoute('fi'),
        ]),
    },
];
const getDevRoutes = () => {
    if (process.env.NODE_ENV === 'production') {
        return [];
    }
    return [
        {
            path: '/_next/webpack-hmr',
            key: 'hmr',
            exact: true,
            component: () => React.createElement(React.Fragment, null),
            type: RouteType.component,
        },
    ];
};
export const routes = () => [
    ...getDevRoutes(),
    ...getSwedishRoutes({ path: '/sv' }),
    ...getRussianRoutes({ path: '/ru' }),
    ...getEnglishRoutes({ path: '/en' }),
    ...getFinnishRoutes({ path: '/' }),
];
