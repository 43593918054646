var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    isLoggedIn: false,
    justLoggedIn: false,
    justLoggedOut: false,
    showLoginModal: false,
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            const { accountId, tukkukorttiCustomers, jwt, name, showLoginModal, justLoggedIn, digitalServices, personalisedContent, } = action.payload;
            state.isLoggedIn = true;
            state.jwt = jwt;
            state.tukkukorttiCustomers = tukkukorttiCustomers;
            state.justLoggedIn = justLoggedIn;
            state.justLoggedOut = false;
            state.accountId = accountId;
            state.name = name;
            state.showLoginModal = showLoginModal;
            state.digitalServices = filterDigitalServices(digitalServices);
            state.personalisedContent = personalisedContent;
        },
        setIsLoggedOut: (state, action) => {
            state.isLoggedIn = false;
            state.justLoggedIn = false;
            state.justLoggedOut = action.payload.justLoggedOut;
            state.showLoginModal = action.payload.showLoginModal;
        },
        setDigitalServices: (state, action) => {
            state.digitalServices = filterDigitalServices(action.payload);
        },
    },
});
export const { reducer } = authSlice;
export const { setDigitalServices, setIsLoggedIn, setIsLoggedOut } = authSlice.actions;
const filterDigitalServices = (digitalServices) => {
    if (Array.isArray(digitalServices)) {
        return digitalServices.filter((ds) => ds.Description !== 'Kespro.com');
    }
    return undefined;
};
