import React from 'react';
import { LabelledButtonBar } from './LabelledButtonBar.js';
import { ShareButton } from './ShareButton.js';
const socialNetworks = [
    'facebook',
    'twitter',
    'googleplus',
    'whatsapp',
];
export const SharingButtons = (props) => (React.createElement(LabelledButtonBar, { label: React.createElement("div", null,
        React.createElement("strong", null, "jaa artikkeli")), buttons: socialNetworks.map((network) => React.createElement(ShareButton, { key: network, network: network, pageUrl: props.pageUrl, text: props.text })) }));
