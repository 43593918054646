// Breakpoints based on _breakpoints.scss
export const breakPoint = {
    MOBILE: 425,
    TABLET: 768,
    DESKTOP: 1024,
};
const DEFAULT_SIZES = '100vw';
const getDefaultImgSrcSet = (img) => [
    ...Object.values(breakPoint).map((bp) => `${img}?w=${bp}&q=75&fm=webp ${bp}w`),
    `${img}?w=1440&q=75&fm=webp 1440w`,
].join(', ');
const getDefaultImgFallback = (img) => `${img}?w=1440&q=75&fm=jpg`;
export const getDefaultContentfulImgSrcSet = (img) => ({
    srcSet: getDefaultImgSrcSet(img),
    sizes: DEFAULT_SIZES,
    fallback: getDefaultImgFallback(img),
});
export const getContentfulImgSrcSet = (img, viewportWidth) => ({
    srcSet: getDefaultImgSrcSet(img),
    sizes: `${viewportWidth}vw`,
    fallback: getDefaultImgFallback(img),
});
export const getAspectRatioImgSrcSet = (img, aspectRatio) => ({
    srcSet: [
        ...Object.values(breakPoint).map((bp) => `${img}?w=${bp}&h=${Math.round(bp / aspectRatio)}&fit=crop&q=75&fm=webp ${bp}w`),
        `${img}?w=1440&q=75&fm=webp 1440w`,
    ].join(', '),
    sizes: DEFAULT_SIZES,
    fallback: getDefaultImgFallback(img),
});
const DEVICE_PIXEL_RATIOS = [1, 1.5, 2, 2.5, 3];
export const getStaticContentfulImgSrcSet = (img, staticWidth, staticHeight) => ({
    srcSet: DEVICE_PIXEL_RATIOS.map((multiplier) => `${img}?w=${Math.round(staticWidth * multiplier)}` +
        `${staticHeight ? `&h=${Math.round(staticHeight * multiplier)}` : ''}&q=75&fm=webp ${multiplier}x`).join(', '),
    fallback: `${img}?w=${staticWidth}&h=${staticHeight}&q=75&fm=jpg`,
});
export const getProductImageSrcSet = (images, staticWidth, staticHeight) => {
    const img = images[0].url;
    return {
        srcSet: DEVICE_PIXEL_RATIOS.map((multiplier) => `${img}?w=${Math.round(staticWidth * multiplier)}` +
            `${staticHeight ? `&h=${Math.round(staticHeight * multiplier)}` : ''}&auto=format ${multiplier}x`).join(', '),
        fallback: `${img}?w=${staticWidth}&h=${staticHeight}&fm=jpg`,
    };
};
