import React from 'react';
// REMEMBER TO UPDATE THIS NUMBER IF YOU ADD A NEW ICON
const cachebustingVersion = 18;
function isWidthHeight(value) {
    return value !== undefined
        && typeof value !== 'number'
        && typeof value !== 'string';
}
function toWidthHeight(value) {
    if (isWidthHeight(value)) {
        return value;
    }
    else {
        return {
            width: value || 20,
            height: value || 20,
        };
    }
}
export const Icon = (props) => {
    const { width, height } = toWidthHeight(props.size);
    const imageSrc = `/assets/images/icons.svg?version=${cachebustingVersion}#${props.type}`;
    return (React.createElement("svg", { className: props.className, onClick: props.onClick, style: props.noStyles
            ? undefined
            : {
                width: `${width}px`,
                height: `${height}px`,
                minWidth: `${width}px`,
                minHeight: `${height}px`,
                fill: 'currentColor',
            } },
        props.title &&
            React.createElement("title", null, props.title),
        React.createElement("use", { key: props.type, xlinkHref: imageSrc })));
};
