import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { HorizontalContainer, VerticalContainer } from '../FlexboxContainer.js';
import { HashLink } from 'react-router-hash-link';
import { Button } from '../Button.js';
import { LandingPageElementType } from '../../../common/apiTypes.js';
import { Icon } from '../../Icon.js';
import { LinkWithScroll } from '../../util/scroll.js';
import styles from './LinksBox.scss';
export function LinksBox(props) {
    const { links, strings } = props;
    const [isNewsletterSignUpModalOpen, setIsNewsletterSignUpModalOpen] = useState(false);
    const halfwayIndex = Math.round(links.length / 2);
    const leftList = linkList(links.slice(0, halfwayIndex));
    const rightList = linkList(links.slice(halfwayIndex));
    const closeModal = () => {
        document.body.style.overflow = 'visible';
        setIsNewsletterSignUpModalOpen(false);
    };
    const openModal = () => {
        setIsNewsletterSignUpModalOpen(true);
    };
    return (React.createElement(VerticalContainer, { horizontalAlign: "flex-start", className: styles.linksbox },
        React.createElement("h3", null, strings.linksHeader),
        React.createElement(HorizontalContainer, { horizontalAlign: "space-between", className: styles.links },
            leftList,
            rightList),
        React.createElement(Button, { "data-pw": "newsletterSignUpButton", classNameButtonContent: styles.newsLetterSignUpButtonContent, className: styles.newsletterSignUpButton, size: "small" /* ButtonSize.Small */, buttonType: "primary" /* ButtonVariant.Primary */, type: LandingPageElementType.Button, isExternalLink: false, text: 'Tilaa uutiskirje', onClickAction: openModal }),
        isNewsletterSignUpModalOpen ? (React.createElement(ReactModal, { isOpen: isNewsletterSignUpModalOpen, onRequestClose: closeModal, onAfterOpen: () => document.body.style.overflow = 'hidden', appElement: document.getElementById('app') || undefined, className: styles.modal, style: { overlay: { zIndex: 999 } } },
            React.createElement("button", { "data-pw": "newsletterModalCloseButton", className: styles.modalCloseButton, onClick: closeModal },
                React.createElement("span", null, "Sulje"),
                React.createElement(Icon, { type: "clear", size: 50 })),
            React.createElement("div", { className: styles.modalContainer },
                React.createElement("iframe", { "data-pw": "newsletterIframe", className: styles.iframe, src: `https://de64fb7cd99849d18410d9be1229c58b.svc.dynamics.com/t/formsandbox/n75E1sKhg7bArZiHfbY9nZfXHcdUNj6rPJvIWcVK9ZE/2097c35e-1519-eb11-a813-000d3ad9841b?ad="${encodeURIComponent(document.location.toString())}` })))) : undefined));
}
function linkList(links) {
    const linksElems = links.map((link) => React.createElement("li", { key: link.caption }, link.external ?
        React.createElement("a", { href: link.to, className: styles.link, target: link.blank ? '_blank' : undefined, rel: link.blank ? 'noopener noreferrer' : undefined }, link.caption)
        : link.hashLink
            ? React.createElement(HashLink, { to: link.to, className: styles.link, target: link.blank ? '_blank' : undefined, smooth: true }, link.caption)
            : React.createElement(LinkWithScroll, { to: link.to, className: styles.link, target: link.blank ? '_blank' : undefined }, link.caption)));
    return React.createElement("ul", { className: styles.list }, linksElems);
}
